@use "node_modules/@tandem-mobile/react-components/react-components";

.container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem 0;
  background: var(--container-background);
  box-shadow: react-components.$standard-shadow;

  // by default clip the box shadow on top and bottom
  clip-path: inset(0 -6px);

  &:not(.lastOfDay) {
    border-top: 1px solid var(--secondary);
  }
}

.firstOfDay {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  &:not(.lastOfDay) {
    clip-path: inset(0 -6px -6px);
  }
}

.lastOfDay {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-top: 0.5rem;

  &:not(.firstOfDay) {
    clip-path: inset(-6px -6px 0);
  }
}

.firstOfDay.lastOfDay {
  clip-path: none;
}
