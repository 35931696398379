@use "node_modules/@tandem-mobile/react-components/react-components";

.percentage {
  height: 3rem;
  width: 3rem;
  display: grid;
}

.percentageFill {
  stroke: var(--primary);
  fill: transparent;
  stroke-width: 3.5;
  grid-area: 1 / 1 / 2 / 2;
}

.percentageCircle {
  stroke: var(--tertiary);
  fill: transparent;
  stroke-width: 3.5;
  grid-area: 1 / 1 / 2 / 2;
}

.percentageCorner {
  stroke: transparent;
  fill: var(--primary);
  grid-area: 1 / 1 / 2 / 2;
}

.percentageValue {
  font-size: react-components.$font-size-large;
  font-weight: react-components.$font-weight-semibold;
  color: var(--primary);
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
