@use "node_modules/@tandem-mobile/react-components/react-components";
@use "src/styles/header";

$icon-size: 2.5rem;

.container {
  display: flex;
  justify-content: space-between;
  padding: header.$padding-top header.$padding-horizontal header.$padding-bottom;
  height: header.$height;
  box-sizing: border-box;
  margin: auto;
  gap: 1rem;
}

.data {
  align-self: stretch;
  color: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
}


.errorContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
  color: var(--error);
}

.errorIcon {
  flex-shrink: 0;
  height: $icon-size;
  width: $icon-size;
  fill: currentcolor;
}

.errorText {
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-semibold;
}

.info {
  font-size: react-components.$font-size-xs;
  font-weight: react-components.$font-weight-regular;
}

.userInitial {
  font-size: react-components.$font-size-large;
  height: 2.5rem;
  width: 2.5rem;
}
