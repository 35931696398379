@use "node_modules/@tandem-mobile/react-components/react-components";
@use "src/styles/header";

.container {
  height: header.$height;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: header.$padding-top header.$padding-horizontal header.$padding-bottom;
  align-items: center;
  box-sizing: border-box;
  column-gap: 0.5rem;
}

.backButton,
.homeButton {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  color: var(--primary);
  justify-self: start;
}

.backButtonIcon,
.homeButtonIcon {
  fill: currentcolor;
}

.centerText {
  @extend %single-line-ellipsis;

  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  grid-column: 2 / 2;
  grid-row: 1 / 1;
}

.title {
  @extend %single-line-ellipsis;

  font-size: react-components.$font-size-xl;
  font-weight: react-components.$font-weight-regular;
  max-width: 100%;
}

.userName {
  @extend %single-line-ellipsis;

  font-size: react-components.$font-size-xs;
  font-weight: react-components.$font-weight-regular;
  max-width: 100%;
}

.menuButton {
  grid-column: 3 / 3;
  grid-row: 1 / 1;
  justify-self: end;
  font-size: react-components.$font-size-large;
  height: 2.5rem;
  width: 2.5rem;
}
