@use "node_modules/@tandem-mobile/react-components/react-components";
@use "sass:math";
@use "src/styles/home.scss";
@use "src/styles/graph.module.scss";

.container {
  position: absolute;
  top: graph.$top-padding;
  height: var(--graph-data-height);
  left: 0;
  right: 0;
}

.urgentLow {
  border-color: var(--error);
  border-top-style: solid;
  border-width: graph.$graph-line-width;
  left: 0;
  position: absolute;
  right: 0;
  transform: translateY(math.div(graph.$graph-line-width, 2));
}

.normalRange {
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--accent-three);
}

.dexcomAttribution {
  position: absolute;
  left: home.$column-gap;
  bottom: 0;
  font-size: react-components.$font-size-xxxs;
  font-weight: react-components.$font-weight-light;
}
