@use "node_modules/@tandem-mobile/react-components/react-components";
@use "./UserHeader.module.scss";

.units {
  font-size: react-components.$font-size-xxs;
  font-weight: react-components.$font-weight-regular;
}

.readingContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: -0.125rem 0 -0.25rem;
}

.reading {
  font-size: react-components.$font-size-xxxl;
  font-weight: react-components.$font-weight-semibold;
}

.trendIcon {
  fill: currentcolor;
  height: 1.75rem;
  width: 1.75rem;
}

.delta {
  font-size: react-components.$font-size-small;
  font-weight: react-components.$font-weight-regular;
}
