@use "node_modules/@tandem-mobile/react-components/react-components";

.nav {
  align-items: stretch;
  background: var(--theme-mode);
  color: var(--primary);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: stretch;
  justify-content: space-between;
  overflow: auto;
  position: fixed;
  inset-inline-end: 0;
  transition-property: transform;
  width: 20rem;

  &,
  &[data-status="initial"],
  &[data-status="close"] {
    transform: translateX(100%);
  }

  &[data-status="open"] {
    transform: translateX(0%);
  }
}

.overlay {
  background: rgba(rgb(react-components.$black), 0.4);
  transition-property: opacity;
  z-index: 1;

  &,
  &[data-status="initial"],
  &[data-status="close"] {
    opacity: 0;
  }

  &[data-status="open"] {
    opacity: 1;
  }
}

.userList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-shrink: 1;
  overflow: auto;
}

.accountsHeading {
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-semibold;
  padding: 0.75rem 1.25rem;
}

.navigationList {
  box-shadow: react-components.$modal-shadow;
  padding-bottom: 2.125rem;
}

.userHeading {
  align-items: center;
  padding: 0.75rem 1.25rem;
  color: var(--primary);
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  text-decoration: none;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-semibold;
}

.smallUserInitial {
  height: 1.75rem;
  width: 1.75rem;
  font-size: react-components.$font-size-small;
}

.link {
  @include react-components.button;
  @include react-components.standard-focus;

  align-items: center;
  padding: calc(1rem - react-components.$focus-border-width) 1.5rem;
  appearance: none;
  background: transparent;
  color: var(--primary);
  display: flex;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-regular;
  gap: 1.25rem;
  justify-content: flex-start;
  text-decoration: none;

  & + & {
    @include react-components.standard-focus {
      border-top: react-components.$focus-border-width solid react-components.$focus-border-color;
      padding-top: calc(1rem - react-components.$focus-border-width)
    }

    border-top: 1px solid var(--tertiary);
    padding: calc(1rem - 1px) 1.5rem calc(1rem - react-components.$focus-border-width);
  }
}

.linkIcon {
  flex-shrink: 0;
  height: 1.25rem;
  width: 1.25rem;
  fill: currentcolor;
}
