@use "node_modules/@tandem-mobile/react-components/react-components";

.container {
  position: relative;
  z-index: 0;
}

.delete {
  color: var(--primary);
  fill: currentcolor;
  opacity: 0.5;
  position: absolute;
  inset-inline-end: -1rem;
  top: -1rem;
  z-index: 1;

  &.deleteHidden,
  &.deleteHidden:disabled {
    opacity: 0;
    cursor: default;
    pointer-events: none;
  }
}

@mixin tile {
  @include react-components.button;
  @include react-components.standard-focus;
  @include react-components.pressed;

  background-color: var(--container-background);
  border-radius: 0.5rem;
  box-shadow: react-components.$standard-shadow;
  box-sizing: border-box;
  color: var(--primary);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 8rem;
  line-height: 1.2;
  overflow: hidden;
  padding: 0.625rem;
  text-align: start;
  width: 100%;
}

.tile {
  @include tile;
}

.overlayTile {
  @include tile;

  cursor: grabbing;
}

.title {
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-regular;
  hyphens: auto;
}

.titleIcon {
  fill: currentcolor;
  height: 0.75rem;
  width: 0.75rem;
  margin-inline-end: 0.25rem;
}

.unitIcon {
  fill: currentcolor;
  height: 1rem;
  width: 1rem;
  margin-inline-end: 0.25rem;
}

.timeSpan {
  font-size: react-components.$font-size-xs;
  font-weight: react-components.$font-weight-light;
  flex-grow: 1;
  margin-bottom: 0.125rem;
}

.value {
  font-size: react-components.$font-size-xxl;
  font-weight: react-components.$font-weight-regular;
  margin-bottom: -3px;

  &:last-of-type {
    margin-bottom: calc(react-components.$font-size-xs * 1.2 - 3px);
  }
}

.valuePercentSymbol {
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-regular;
}

.unit {
  display: flex;
  align-items: center;
  font-size: react-components.$font-size-xs;
  font-weight: react-components.$font-weight-regular;
}
