@use "node_modules/@tandem-mobile/react-components/react-components";

.distributionPercentagesTimeSpan {
  flex-grow: 0 !important;
  margin-bottom: 0.75rem !important;
}

.distributionPercentages {
  border-radius: 0.25rem;
  background-color: var(--data-two);
  height: 1.875rem;
  width: 100%;
  display: flex;
  align-items: center;
}

.distributionPercentage {
  width: 33.33%;
  height: 100%;
  display: grid;
  align-items: center;
}

.distributionPercentageValue {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-size: react-components.$font-size-xxs;
  font-weight: react-components.$font-weight-semibold;
}

.distributionPercentageSymbol {
  font-size: react-components.$font-size-xxxs;
  font-weight: react-components.$font-weight-regular;
}

.distributionWithinNormal {
  position: relative;

  .distributionPercentageValue {
    font-size: react-components.$font-size-xs;
  }

  &::before,
  &::after {
    content: "";
    background-color: var(--data-one);
    height: 50%;
    width: 100%;
    position: absolute;
  }

  &::before {
    top: 0;
    inset-inline-start: 0;
    transform: skew(-10deg);
  }

  &::after {
    top: 50%;
    inset-inline-start: 0;
    transform: skew(10deg);
  }
}
