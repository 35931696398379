@use "node_modules/@tandem-mobile/react-components/react-components";
@use "./themes";

@mixin generate-theme-classes(
  $name,
  $light-theme,
  $dark-theme,
) {
  .#{$name} {
    @media (prefers-color-scheme: light) {
      @include react-components.use-theme($light-theme);
    }

    @media (prefers-color-scheme: dark) {
      @include react-components.use-theme($dark-theme);
    }
  }

  .#{$name}Light {
    @include react-components.use-theme($light-theme);
  }

  .#{$name}Dark {
    @include react-components.use-theme($dark-theme);
  }
}

// bubblegum theme
@include generate-theme-classes(
  "bubblegum",
  themes.$bubblegum-light,
  themes.$bubblegum-dark,
);

// cavern theme
@include generate-theme-classes(
  "cavern",
  themes.$cavern-light,
  themes.$cavern-dark,
);

// chocolate theme
@include generate-theme-classes(
  "chocolate",
  themes.$chocolate-light,
  themes.$chocolate-dark,
);

// desert theme
@include generate-theme-classes(
  "desert",
  themes.$desert-light,
  themes.$desert-dark,
);

// flex theme
@include generate-theme-classes(
  "flex",
  themes.$flex-light,
  themes.$flex-dark,
);

// midnight theme
@include generate-theme-classes(
  "midnight",
  themes.$midnight-light,
  themes.$midnight-dark,
);

// midnight theme
@include generate-theme-classes(
  "midnight",
  themes.$midnight-light,
  themes.$midnight-dark,
);

// moon-moss theme
@include generate-theme-classes(
  "moon_moss",
  themes.$moon-moss-light,
  themes.$moon-moss-dark,
);

// pacific theme
@include generate-theme-classes(
  "pacific",
  themes.$pacific-light,
  themes.$pacific-dark,
);

// sea-green theme
@include generate-theme-classes(
  "sea_green",
  themes.$sea-green-light,
  themes.$sea-green-dark,
);

// strawberry-lemonade theme
@include generate-theme-classes(
  "strawberry_lemonade",
  themes.$strawberry-lemonade-light,
  themes.$strawberry-lemonade-dark,
);

// sugarmate theme
@include generate-theme-classes(
  "sugarmate",
  themes.$sugarmate-light,
  themes.$sugarmate-dark,
);

// sunrise-sunset theme
@include generate-theme-classes(
  "sunrise_sunset",
  themes.$sunrise-sunset-light,
  themes.$sunrise-sunset-dark,
);

// tandem theme
@include generate-theme-classes(
  "tandem",
  themes.$tandem-light,
  themes.$tandem-dark,
);

// titanium theme
@include generate-theme-classes(
  "titanium",
  themes.$titanium-light,
  themes.$titanium-dark,
);

// ugly theme
@include generate-theme-classes(
  "ugly",
  themes.$ugly-light,
  themes.$ugly-dark,
);

// y2k-revival theme
@include generate-theme-classes(
  "y2k_revival",
  themes.$y2k-revival-light,
  themes.$y2k-revival-dark,
);
