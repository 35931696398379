@use "node_modules/@tandem-mobile/react-components/react-components";

.container {
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
}

.rangeInputSlider {
  flex-grow: 1;
}

.sleepInput {
  width: 9.125rem;
}

.selectContainer {
  margin-top: 0.75rem;
}

.sleepInputSeparator {
  width: 1.25rem;
  margin: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-semibold;
}
