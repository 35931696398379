@use "node_modules/@tandem-mobile/react-components/react-components.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  overflow: hidden;
}

.dots {
  position: relative;
}

.dotButton {
  @include react-components.button;

  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  height: 1rem;
  justify-content: center;
  inset-inline-start: -0.5rem;
  outline: none;
  position: absolute;
  top: -0.5rem;
  transition-duration: 0.5s;
  transition-property: transform;
  width: 1rem;
}

.hiddenDotButton {
  pointer-events: none;
}

.dot {
  @include react-components.button;

  background-color: var(--tertiary);
  border-radius: 0.5rem;
  height: 0.5rem;
  opacity: 1;
  transition-duration: 0.5s;
  transition-property: transform, background-color, opacity;
  width: 0.5rem;
}

.activeDot {
  background-color: var(--accent-one);
}

.hiddenDot {
  opacity: 0;
}
