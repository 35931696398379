@use "node_modules/@tandem-mobile/react-components/react-components.scss";

// styles for dialogs

@mixin dialog-body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.dialogBody {
  @include dialog-body;

  gap: 1.5rem;
}

.dialogBodyMultiParagraph {
  @include dialog-body;

  gap: 1.2em;
}

// use this to style submit buttons that look like dialog buttons
.dialogMultiButton {
  @include react-components.dialog-multi-button;
}
