@use "node_modules/@tandem-mobile/react-components/react-components";
@use "src/styles/forms";
@use "src/styles/public";

.container {
  @include public.container;

  padding-bottom: 1rem;
}

.submit {
  @include public.submit;

  margin-top: 1.25rem;
}

.link {
  align-self: center;
  color: var(--secondary);
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-semibold;
}
