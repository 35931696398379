@use "node_modules/@tandem-mobile/react-components/react-components";

.form {
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
}

.toggleRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.375rem 0;
}

.customDateValidationContainer {
  display: flex;
}

.customDateInput {
  width: 9.125rem;
}

.emailInput {
  width: 100%;
}

.customDateInputSeparator {
  width: 1.25rem;
  margin: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-semibold;
}

.submit {
  align-self: center;
}
