@use "node_modules/@tandem-mobile/react-components/react-components.scss";
@use "src/styles/modals";

.addButton {
  @include react-components.button;
  @include react-components.pressed;
  @include react-components.disabled;
  @include react-components.standard-focus;
  @extend %single-line-ellipsis;

  background: none;
  border-radius: 0.5rem;
  color: var(--primary);
  font-size: react-components.$font-size-xm;
  font-weight: react-components.$font-weight-semibold;
  padding: 0 1rem;
}

.modalBody {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.25rem;
}

.modalHeading {
  position: sticky;
  top: 0;
  background: var(--theme-mode);
  z-index: 1;
}

.sectionHeading {
  font-size: react-components.$font-size-xm;
  font-weight: react-components.$font-weight-semibold;
  margin-bottom: 0.75rem;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
}

.button {
  position: relative;
}
