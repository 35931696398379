@use "node_modules/@tandem-mobile/react-components/react-components";
@use "sass:math";

.container {
  @include react-components.button;

  @include react-components.standard-focus {
    border-right-width: 0;
    border-left-width: 0;
  };

  align-items: center;
  border-left-width: 0;
  border-right-width: 0;
  background: transparent;
  color: var(--primary);
  display: flex;
  font-size: react-components.$font-size-medium;
  gap: 0.625rem;
  padding: calc(0.5rem - #{react-components.$focus-border-width}) 0;

  &:disabled {
    cursor: default;
  }
}

.time {
  text-align: start;
  flex-grow: 1;
  font-weight: react-components.$font-weight-semibold;
}

.summary {
  font-weight: react-components.$font-weight-regular;
  display: flex;
  align-items: center;
}

.summaryIcon {
  height: 0.875rem;
  width: 0.875rem;
  fill: currentcolor;
}

.pre {
  margin: 0;
  padding: 0;
}
