@use "node_modules/@tandem-mobile/react-components/react-components.scss";
@use "src/styles/modals.scss";

.datetime {
  @include react-components.button;
  @include react-components.standard-focus;
  @include react-components.disabled;
  @include react-components.pressed;

  background: none;
  color: var(--primary);
  display: block;
  font-size: 0.875rem;
  font-weight: react-components.$font-weight-regular;
  margin-top: 0.5rem;
  text-align: start;
}

.icon {
  color: var(--tertiary);
  margin-inline-start: 0.375rem;
  fill: currentcolor;
}

.done {
  align-self: flex-end;
}

.label {
  margin-bottom: 0.5rem;
}

.input {
  width: 100%;
}
