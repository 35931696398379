@use "node_modules/@tandem-mobile/react-components/react-components";

.container {
  @include react-components.button;
  @include react-components.standard-focus;

  height: 4.375rem;
  padding-inline:
    calc(1.25rem - react-components.$focus-border-width)
    calc(0.5rem - react-components.$focus-border-width);
  background-color: var(--data-two);
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--primary);
  position: relative;
}

.selectedUser {
  &::before {
    content: '';
    position: absolute;
    inset-inline-start: -1 * react-components.$focus-border-width;
    top: -1 * react-components.$focus-border-width;
    bottom: -1 * react-components.$focus-border-width;
    width: 0.5rem;
    background: var(--accent-one);
  }
}

.userInitial {
  height: 2.5rem;
  width: 2.5rem;
  font-size: react-components.$font-size-large;
  margin-inline-end: 1rem;
}

.userText {
  @extend %single-line-ellipsis;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex-grow: 1;
}

.userName {
  @extend %single-line-ellipsis;

  font-size: react-components.$font-size-xl;
  font-weight: react-components.$font-weight-semibold;
}

.selectedUserText {
  font-size: react-components.$font-size-xs;
  font-weight: react-components.$font-weight-regular;
  color: var(--secondary);
}
