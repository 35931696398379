@use "node_modules/@tandem-mobile/react-components/react-components";

.quartiles {
  height: 2.75rem;
  width: 100%;
  display: grid;
}

/*
 * TODO
 * designs say to use data-one for the center part of the curve
 * but there's not an easy way to do that
 */
.quartilesCurve {
  fill: var(--data-two);
  height: 2.75rem;
  width: 100%;
  grid-area: 1 / 1 / 2 / 2;
}

.quartilesSeparators {
  color: var(--tertiary);
  height: 2.75rem;
  width: 100%;
  grid-area: 1 / 1 / 2 / 2;
  stroke-linecap: round;
  stroke-width: 1;
  fill: none;
  stroke: currentcolor;
  overflow: visible;
}

.quartilesValues {
  display: flex;
  grid-area: 1 / 1 / 2 / 2;
}

%quartile {
  width: 33.33%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}

.quartile1 {
  @extend %quartile;

  font-size: react-components.$font-size-xxs;
  font-weight: react-components.$font-weight-semibold;
  justify-content: flex-end;
}

.quartile2 {
  @extend %quartile;

  font-size: react-components.$font-size-small;
  font-weight: react-components.$font-weight-semibold;
  justify-content: center;
}

.quartile3 {
  @extend %quartile;

  font-size: react-components.$font-size-xxs;
  font-weight: react-components.$font-weight-semibold;
  justify-content: flex-start;
}
