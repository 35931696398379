@use "node_modules/@tandem-mobile/react-components/react-components";

.container {
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.labelRow {
  align-items: center;
  display: flex;
  height: 2.5rem;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

.list {
  appearance: none;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.listItem {
  background-color: var(--container-background);
  border-radius: 0.5rem;
  box-shadow: react-components.$standard-shadow;
  color: var(--primary);
  display: flex;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-regular;
  padding: 1rem;
  justify-content: space-between;
}

.listItemText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;

  @extend %single-line-ellipsis;
}

.listItemTopRow {
  @extend %single-line-ellipsis;

  max-width: 100%;
}

.listItemName {
  font-weight: react-components.$font-weight-semibold;
  margin-inline-end: 0.5rem;
}

.listItemForm,
.listItemUnit {
  color: inherit;
}

.listItemDescription {
  @extend %single-line-ellipsis;

  color: inherit;
  max-width: 100%;
  text-transform: capitalize;
}

.listItemSettingsButton {
  flex-shrink: 0;
}

.emptyButton {
  font-size: react-components.$font-size-medium;
  width: 100%;
}

.loadingButton {
  font-size: react-components.$font-size-medium;
  width: 100%;
  opacity: 0;
}
