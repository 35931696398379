@use "node_modules/@tandem-mobile/react-components/react-components";
@use "src/styles/breakpoints";

.overlay {
  background: rgba(rgb(react-components.$black), 0.7);
  display: grid;
  place-items: center;
  transition-property: opacity;
  backdrop-filter: blur(6px);

  &,
  &[data-status="initial"],
  &[data-status="close"] {
    opacity: 0;
  }

  &[data-status="open"] {
    opacity: 1;
  }
}

.buttons {
  display: grid;
  row-gap: 1.25rem;
  column-gap: 0.75rem;
  pointer-events: none;
  transition-property: transform;

  &,
  &[data-status="initial"],
  &[data-status="close"] {
    transform: scale(0.9);
  }

  &[data-status="open"] {
    transform: scale(1);
  }
}

@media screen and (orientation: portrait) {
  .buttons {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
  }
}

@media screen and (orientation: landscape) {
  .buttons {
    grid-template-rows: repeat(2, 1fr);
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
}

.buttonContainer {
  font-size: react-components.$font-size-xm;
  font-weight: react-components.$font-weight-semibold;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.subentryButton {
  pointer-events: auto;
}

.favoriteButton {
  pointer-events: auto;
}

.plusIcon {
  transition-property: color, transform;
  transform: rotate(0);
}

.createButton {
  position: fixed;

  &[data-status="open"] {
    .plusIcon {
      transform: rotate(-45deg);
    }
  }
}

@media screen and (min-width: breakpoints.$lg-min) {
  .createButton {
    bottom: 1.25rem;
    inset-inline-end: 1.25rem;
  }
}

@media screen and (max-width: breakpoints.$md-max) {
  .createButton {
    bottom: 1.25rem;
    right: 0;
    left: 0;
    margin: auto;
  }
}

.buttonContainerLight {
  color: var(--contrast);
}

.createButtonLight[data-status="open"] {
  background-color: var(--primary);

  .plusIcon {
    color: var(--contrast);
  }
}

.subentryButtonLight {
  background-color: var(--primary);
  color: var(--contrast);
}

.buttonContainerDark {
  color: var(--primary);
}

.createButtonDark[data-status="open"] {
  background-color: var(--contrast);

  .plusIcon {
    color: var(--primary);
  }
}

.favoriteButtonDark {
  background-color: var(--primary);
  color: var(--contrast);
}
