@use "node_modules/@tandem-mobile/react-components/react-components";
@use "sass:math";
@use "src/styles/graph.module.scss";
@use "src/styles/breakpoints";

.container {
  @include react-components.button;
  @include react-components.standard-focus;

  appearance: none;
  background: transparent;
  height: graph.$vertical-line-height;
  display: flex;
  align-items: stretch;
  padding-inline: 0.125rem;
  border-inline-start: solid graph.$graph-line-width var(--primary);
}

@media screen and (min-width: breakpoints.$lg-min) {
  .container {
    padding-inline-end: 0;
  }
}

.unitsContainer {
  width: 1rem;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.units {
  font-size: graph.$graph-font-size;
  hyphens: none;
  transform: rotate(90deg);
}

.labels {
  position: relative;
  height: var(--graph-data-height);
  top: math.div(graph.$graph-font-size, 2);
  width: 1.5rem;
}
