@use "node_modules/@tandem-mobile/react-components/react-components";
@use "./UserHeader.module.scss";

$loading-animation-duration: 0.5s;

.loadingContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.loadingIcon {
  height: UserHeader.$icon-size;
  width: UserHeader.$icon-size;
  fill: currentcolor;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loadingText {
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-semibold;
}

.loadingBar {
  width: 0.25rem;
  height: 100%;
  background: var(--tertiary);
  border-radius: 0.125rem;

  @media (prefers-reduced-motion: no-preference) {
    animation: loading $loading-animation-duration ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    &[data-index="0"] {
      animation-delay: 0 * $loading-animation-duration;
    }

    &[data-index="1"] {
      animation-delay: 0.5 * $loading-animation-duration;
    }

    &[data-index="2"] {
      animation-delay: 1 * $loading-animation-duration;
    }

    &[data-index="3"] {
      animation-delay: 1.5 * $loading-animation-duration;
    }

    &[data-index="4"] {
      animation-delay: 2 * $loading-animation-duration;
    }
  }
}

@keyframes loading {
  0% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(0.5);
  }
}
