@use "node_modules/@tandem-mobile/react-components/react-components.scss";
@use "src/styles/modals";

.modalHeading {
  display: flex;
  align-items: center;
}

.modalHeadingIcon {
  height: 1.25rem;
  width: 1.25rem;
  fill: currentcolor;
  margin-inline-end: 0.5rem;
}
