@use "node_modules/@tandem-mobile/react-components/react-components";

.container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 0.125rem;
  margin-inline-start: 0.25rem;
}

.reading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.readingTime {
  font-size: react-components.$font-size-xxs;
  font-weight: react-components.$font-weight-regular;
}

.readingValue {
  font-size: react-components.$font-size-xl;
  font-weight: react-components.$font-weight-regular;
}

.readingUnit {
  font-size: react-components.$font-size-xxs;
  font-weight: react-components.$font-weight-regular;
}

.trendIcon {
  height: 1rem;
  fill: currentcolor;
}
