@use "node_modules/@tandem-mobile/react-components/react-components.scss";
@use "src/styles/public";
@use "src/styles/forms";

.container {
  @include public.container;

  padding-bottom: 1rem;
}

.submit {
  @include public.submit;

  margin-top: 1.25rem;
}

.next {
  @include public.submit;

  margin-top: 0.6125rem;
}

.description {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-light;
  gap: 1.2 * react-components.$font-size-medium;
}

// these styles match the data source styles
label.checkbox {
  font-size: react-components.$font-size-medium;
  padding: 0;
  flex-grow: 1;
  justify-content: start;
}

.privacyCheckboxContainer {
  border: 1px solid var(--primary);
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  background: transparent;
  transition-property: background;
  transition-duration: react-components.$button-transition-duration;

  &[data-checked="true"] {
    background: var(--container-background);
  }
}

.link {
  @include react-components.standard-focus;

  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 4px;
}

.linkIcon {
  color: var(--primary);
  fill: currentcolor;
  height: 1.25rem;
  width: 1.25rem;
}
