@use "node_modules/@tandem-mobile/react-components/react-components";
@use "src/styles/breakpoints";
@use "src/styles/home.scss";
@use "./Tile/Tile.module.scss";

.container {
  display: flex;
  flex-direction: column;
}

.tiles {
  font-family: react-components.$font-family-montserrat;
  gap: 0.5rem;
  overflow-y: auto;
  padding: home.$bottom-padding home.$column-gap;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7.5rem, 1fr));
  align-items: stretch;
}

@media screen and (max-width: breakpoints.$md-max) {
  .tilesHidden {
    display: none;
  }
}

@media screen and (min-width: breakpoints.$lg-min) {
  .tiles {
    margin-inline-end: -1 * home.$column-gap;
  }
}

.newInsightTile {
  @include Tile.tile;
  @include react-components.button;
  @include react-components.standard-focus;
  @include react-components.pressed;

  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: dashed 1px var(--tertiary);
  gap: 1rem;
  font-size: react-components.$font-size-medium;
}

.newInsightTileIcon {
  color: var(--tertiary);
  fill: currentcolor;
  height: 3rem;
  width: 3rem;
}
