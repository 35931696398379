@use "node_modules/@tandem-mobile/react-components/react-components.scss";

.container {
  @include react-components.button;

  @include react-components.standard-focus {
    padding-top: calc(1.25rem - 1px);
    padding-bottom: calc(0.75rem - 1px);
    border-right-width: 0;
    border-left-width: 0;
  };

  align-items: center;
  background: transparent;
  border: 1px dashed var(--secondary);
  border-left-width: 0;
  border-right-width: 0;
  color: var(--primary);
  display: flex;
  justify-content: stretch;
  padding-bottom: 0.75rem;
  padding-top: 1.25rem;
  text-align: start;

  & + & {
    @include react-components.standard-focus {
      padding-top: calc(1.25rem - 2px);
      padding-bottom: calc(0.75rem - 1px);
      border-right-width: 0;
      border-left-width: 0;
    };

    border: 1px dashed var(--secondary);
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    margin-top: -1px;
  }
}

.icon {
  fill: currentcolor;
  display: grid;
  height: 1.25rem;
  margin-inline-end: 1rem;
  place-items: center;
  width: 1.25rem;
  flex-shrink: 0;
  color: var(--primary);
}

.text {
  @extend %single-line-ellipsis;

  color: var(--primary);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-regular;
  gap: 0.25rem;
}

.title {
  @extend %single-line-ellipsis;
}

.description {
  @extend %single-line-ellipsis;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message {
  @extend %single-line-ellipsis;

  color: var(--secondary);
}

.noteIcon {
  fill: currentcolor;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  margin-inline-end: 0.25rem;
}

.dataContainer {
  align-items: center;
  display: flex;
  color: var(--secondary);
}

.data {
  margin-inline-start: 0.75rem;
}

.imageIcon {
  fill: currentcolor;
  height: 1.25rem;
  margin-inline-start: 0.75rem;
  width: 1.25rem;
}
