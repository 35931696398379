@use "node_modules/@tandem-mobile/react-components/react-components";

$top-padding: 1.25rem;
$bottom-padding: 1rem;
$column-gap: 1rem;
$row-gap: 0.5rem;
$heading-height: 2.5rem;
$heading-font-size: react-components.$font-size-xm;
$heading-font-weight: react-components.$font-weight-bold;
$heading-bottom-padding: 0.5rem;
$active-tab-border-bottom: 2px solid var(--accent-one);

@mixin heading-text {
  border-bottom: $active-tab-border-bottom;
  font-size: $heading-font-size;
  font-weight: $heading-font-weight;
  margin: 0;
  padding: 0 0 $heading-bottom-padding;
  color: var(--primary);
}
